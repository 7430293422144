import { gql } from '@apollo/client'
import { PageOptions } from '@graphcommerce/framer-next-pages'
import { ProductListDocument, ProductListQuery } from '@graphcommerce/magento-product'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { GetStaticProps, LayoutHeader, MetaRobots, PageMeta } from '@graphcommerce/next-ui'
import { LayoutNavigation, LayoutNavigationProps, RowProduct, RowRenderer } from '../components'
import { LayoutDocument } from '../components/Layout/Layout.gql'
import getRevalidateTime from '../constants'
import { DefaultPageDocument, DefaultPageQuery } from '../graphql/DefaultPage.gql'
import { graphqlSharedClient, graphqlSsrClient } from '../lib/graphql/graphqlSsrClient'

type Props = DefaultPageQuery & {
  favoritesList: ProductListQuery
  homeDesignProducts: ProductListQuery
}
type RouteProps = { url: string }
type GetPageStaticProps = GetStaticProps<LayoutNavigationProps, Props, RouteProps>

function CmsPage(props: Props) {
  const { pages, homeDesignProducts, favoritesList, swipableList } = props
  const page = pages?.[0]

  const latest = homeDesignProducts?.products?.items?.[0]

  return (
    <>
      <PageMeta
        title={page?.metaTitle ?? page?.title ?? ''}
        metaDescription={page?.metaDescription ?? ''}
        metaRobots={page?.metaRobots.toLowerCase().split('_') as MetaRobots[] | undefined}
        canonical='/'
      />

      <LayoutHeader floatingMd floatingSm />

      {page && (
        <RowRenderer
          content={page.content}
          renderer={{
            RowProduct: (rowProps) => {
              const { identity } = rowProps
              if (identity === 'latest-elenco-ambienti') {
                return (
                  <RowProduct
                    {...rowProps}
                    {...latest}
                    items={homeDesignProducts.products?.items}
                  />
                )
              }

              return (
                <RowProduct {...rowProps} {...latest} items={homeDesignProducts.products?.items} />
              )
            },
          }}
        />
      )}
    </>
  )
}

CmsPage.pageOptions = {
  Layout: LayoutNavigation,
} as PageOptions

export default CmsPage

export const getStaticProps: GetPageStaticProps = async ({ locale }) => {
  const client = graphqlSharedClient(locale)
  const staticClient = graphqlSsrClient(locale)

  const conf = client.query({ query: StoreConfigDocument })
  const page = staticClient.query({ query: DefaultPageDocument, variables: { url: `page/home` } })
  const layout = staticClient.query({ query: LayoutDocument })

  // todo(paales): Remove when https://github.com/Urigo/graphql-mesh/issues/1257 is resolved
  // corresponding to https://api.jode.it/index.php/admin_32b71t/catalog/category/edit/id/290
  const homeDesignProducts = staticClient.query({
    query: ProductListDocument,
    variables: { pageSize: 8, filters: { category_uid: { eq: 'Mjkw' } } },
  })

  // const hotelProducts = staticClient.query({
  //   query: ProductListDocument,
  //   variables: { pageSize: 8, filters: { category_uid: { eq: 'Mjkx' } } },
  // })

  if (!(await page)?.data?.pages?.[0]) return { notFound: true }

  const revalidate = getRevalidateTime()

  return {
    props: {
      ...(await page).data,
      ...(await layout).data,
      homeDesignProducts: (await homeDesignProducts).data,
      apolloState: await conf.then(() => client.cache.extract()),
    },
    revalidate,
  }
}
